import React, {useState} from "react";
import * as styles from "src/styles/Mc.module.css";
import { SEO, Picture } from "src/components/commonComponents";
import Modal from 'react-modal';
import ROICalculator from "src/components/blocks/ROICalculator";
import mcHeroBanner from "../../../static/staticPages/mc-hero.jpg"
import featureLogo from "../../../static/staticPages/featureLogo.svg"
import listCheckLogo from "../../../static/staticPages/mc-product/list-check-solid.svg";
import fileCircleLogo from "../../../static/staticPages/mc-product/file-circle-check-solid.svg";
import fileCertificateLogo from "../../../static/staticPages/mc-product/file-certificate-solid.svg";
import boxCircleCheck from "../../../static/staticPages/mc-product/box-circle-check-solid.svg";
import folderMagnify from "../../../static/staticPages/mc-product/folder-magnifying-glass-solid.svg";
import gaurgeMax from "../../../static/staticPages/mc-product/gauge-max-solid.svg";

import transformerBolt from "../../../static/staticPages/mc-product/transformer-bolt-solid.svg";
import mobileSolid from "../../../static/staticPages/mc-product/mobile-solid.svg";
import fileChartColumn from "../../../static/staticPages/mc-product/file-chart-column-solid.svg";
import objectIntersect from "../../../static/staticPages/mc-product/object-intersect-solid.svg";
import warehouseFull from "../../../static/staticPages/mc-product/warehouse-full-solid 22.svg";

import charLineLogom from "../../../static/staticPages/mc-product/chart-line-solid.svg";
import oilWell from "../../../static/staticPages/mc-product/oil-well-solid.svg";
import prescriptionBottle from "../../../static/staticPages/mc-product/prescription-bottle-medical-solid.svg";
import mcLogoN from "../../../static/staticPages/mc-logo.png"
import service4 from "../../../static/staticPages/service4.svg"
import service2 from "../../../static/staticPages/lucernex-logo.png"
import redeyeLogo from "../../../static/staticPages/redeye_logo.png"
import Dot from "../../../static/staticPages/Dot.svg"
import Play from "../../../static/staticPages/Play.svg"
import Play1 from "../../../static/staticPages/Play1.svg"
import arrowDownBlue from "../../../static/staticPages/arrowDownBlue.svg"
import arrowUpBlue from "../../../static/staticPages/arrowUpBlue.svg"
import arrowRightBlue from "../../../static/staticPages/arrowRightBlue.svg"
import benefit1 from "../../../static/staticPages/benefit1.svg"
import benefit2 from "../../../static/staticPages/benefit2.svg"
import benefit3 from "../../../static/staticPages/benefit3.svg"
import benefit4 from "../../../static/staticPages/benefit4.svg"
import benefit5 from "../../../static/staticPages/cook_black.png"
import benefit6 from "../../../static/staticPages/benefit6.svg"
import masters from "../../../static/Assets_Version_A/logos/master-gallery.png"
import tick from "../../../static/staticPages/tick.png"
import fullWidthBannerMc1 from "../../../static/staticPages/encore_wire.png"
import logo1 from "../../../static/staticPages/logo1.svg"
import logo2 from "../../../static/staticPages/logo2.svg"
import logo3 from "../../../static/staticPages/cook_white.png"
import logo4 from "../../../static/staticPages/logo4.svg"
import logo5 from "../../../static/staticPages/logo5.svg"
import logo6 from "../../../static/staticPages/logo6.svg"
import mastersWhite from "../../../static/staticPages/masters_gray.png"
import mcHeroBanner1 from "../../../static/staticPages/mcHeroBanner1.png"
import arrowRight from "../../../static/staticPages/arrowRight.svg"
import resource1 from "../../../static/staticPages/resource_1_mc.jpeg"
import resource2 from "../../../static/staticPages/resource_2_mc.jpeg"
import resource3 from "../../../static/staticPages/resource_3_mc.jpeg"
import resource4 from "../../../static/staticPages/resource_4_mc.jpeg"
import resource5 from "../../../static/staticPages/resource_5_mc.jpeg"
import design1 from "../../../static/staticPages/design1.png"
import design2 from "../../../static/staticPages/design2.svg"
import design3 from "../../../static/staticPages/design3.svg"
import mcLogo from "../../../static/staticPages/mc-logo.png"
import { useHeroImages } from "src/hooks/useHeroImages";
import { getPageDataJsonPath } from "src/utils";

import logoStrip1 from "../../../static/staticPages/emsLogoStrip1.svg"
import logoStrip2 from "../../../static/staticPages/emsLogoStrip2.png"
import logoStrip3 from "../../../static/staticPages/emsLogoStrip3.png"
import logoStrip4 from "../../../static/staticPages/emsLogoStrip4.svg"
import { useFormImages } from "src/hooks/useFormImages";
import { ContentMarketoForm } from "src/components/commonComponents/forms/ContentMarketoForm";

const data = {
  ROICalculator: {
    "type": {
      "id": "roi_calculator"
    },
    "uuid": "78184b28-8a91-47bf-8b65-be5c530fc2c7",
    "nid": 14499,
    // "title": "ROI Calculator",
    "fieldHideTitle": false,
    "fieldShowTitleFullWidth": null,
    "fieldRoiCalculatorId": "dl_calculator",
    "fieldEmbedCode": "<script src=\"https://jamaica.value-cloud.com/embed.js\" width=\"100%\" height=\"100%\" calc=\"9117e314cdbca61feb509d0ada4bcb11\" currency_code=\"USD\" lang=\"en_US\" company=\"company_name\"></script>"
  },
  heroBanner:{
    title: 'Maintenance Connection: Computerized Maintenance Management System (CMMS)',
    description:"Streamline workflows, avoid asset failure, automate critical processes, and reduce downtime with Maintenance Connection's powerful CMMS Software",
    link1: {
      url: '/request-demo',
      text: 'Request a demo'
    },
    link2: {
      url: '/demo',
      text: 'Interactive demo'
    },
    image: {
      url: mcHeroBanner,
      altText: 'Employees in a facility using CMMS software for maintenance management and operational efficiency.'
    },
    logo: {
      url: mcLogo,
      altText: "Accruent Maintenance Connection logo"
    }
  },
  logoStrip:[
    {
      url: logoStrip4,
      altText: 'EMS G2 rating 4.3 stars indicating high user satisfaction'
    },
    {
      url: logoStrip1,
      altText: 'EMS Capterra rating 4.3 stars indicating high user satisfaction'
    },
    {
      url: logoStrip2,
      altText:'EMS Software Advice rating 4.3 stars indicating high user satisfaction'
    },
    {
      url: logoStrip3,
     altText:'EMS GetApp rating 4.3 stars indicating high user satisfaction'
    }
  ],
  benefits: {
    title: "Streamline Maintenance Operations, Maximize Asset Lifespan, and Reduce Downtime",
    description: 'Complete your tech stack and empower your teams to operate more proactively with our fully integrated, scalable CMMS for manufacturing',
    items: [
      {
        logoUrl: listCheckLogo,
        altText: "Check list icon for regulatory compliance",
        title: "Simplify Regulatory Compliance ",
        description: "Centralize documentation, automate tasks, establish audit trails, access reports, and automate workflows to comply with regulatory standards, including FDA 21 CFR PART 11 and ISO 27001",
        // link:{
        //   text:'Explore regulatory compliance',
        //   url:'/'
        // }
      },
      {
        logoUrl: fileCircleLogo,
        altText: "Checked file in for asset availability",
        title: "Maximize Asset Availability",
        description: "Enhance repair speed and reduce costs through efficient spare parts management, optimized labor scheduling, accurate demand forecasting, and more",
        // link:{
        //   text:'Explore asset availability',
        //   url:'/'
        // }
      },
      {
        logoUrl: fileCertificateLogo,
        altText: "File certificate icon for quality control",
        title: "Increase Quality Control and Product Quality",
        description: "Reduce process deviations by ensuring maintenance activities are always executed with the most current procedure and attached with approved documents. Monitor facilities and cleanrooms to identify and act​ on anomalies",
        // link:{
        //   text:'Explore quality control',
        //   url:'/'
        // }
      },
      {
        logoUrl: boxCircleCheck,
        altText: "Check box icon for inventory management",
        title: "Improve Inventory Management",
        description: "Find, purchase, and organize the parts you need to keep your assets running, lower your inventory costs, and get rid of obsolete inventory",
        // link:{
        //   text:'Explore inventory management',
        //   url:'/'
        // }
      },
      {
        logoUrl: folderMagnify,
        altText: "Magnifying glass icon for visibility",
        title: "Enhance Visibility Across Operations ",
        description: "Consolidate data and integrate natively to EDMS and IoT systems to improve your big-picture processes and increase access to accurate and approved asset information ",
        // link:{
        //   text:'Explore operation visibility',
        //   url:'/'
        // }
      },
      {
        logoUrl: gaurgeMax,
        altText: "Gauge icon for measuring efficiency",
        title: "Boost Operational Efficiency ",
        description: "Streamline maintenance processes, reduce downtime, and extend the lifespan of machinery by scheduling regular maintenance, tracking repairs, and managing parts inventory.",
        // link:{
        //   text:'Explore operation visibility',
        //   url:'/'
        // }
      }
    ],
    info:{
      title: "Trusted by industry leaders across 75+ countries",
      images:[
      {
        url:benefit2,
        altText:"Alaska Airline"
      },
      {
        url: benefit6,
        altText: "L'Oreal"
      },
      {
        url:benefit1,
        altText:"The New York Times"
      },
      {
        url:benefit5,
        altText:"Cook Medical"
      },
      {
        url:masters,
        altText:"Masters Gallery Food"
      }
    ],
    link: {
      text:"Request a demo",
      url:'/request-demo'
    }
    }
  },
  threeColTwoRowComp: {
    title:"Simplify Your Facility and Asset Management Once and For All",
    description: "Use robust computerized maintenance management software features to help streamline asset management and facilitate enterprise-wide preventive maintenance",
    items:[
      {
        logoUrl: transformerBolt,
        altText: "transformer with a bolt icon",
        title:"Powerful Work Order Management ",
        description: "Unlock your maintenance team's potential, simplify workflows, maximize ROI, and improve your overall output with centralized, easy-to-use work order management",
      },
      {
        logoUrl: mobileSolid,
        altText: "Mobile icon",
        title:"Mobile CMMS App ",
        description: "Gain anywhere, anytime access to all your mission-critical maintenance tasks. Use these standardized, comprehensive insights to standardize data, update your team, and keep operations running smoothly",
      },
      { 
        logoUrl: fileChartColumn,
        altText: "File chart icon",
        title:"Actionable Reporting and Analytics ",
        description: "Use configurable maintenance reporting software to analyze key metrics – like work order completion rates or labor costs – address pain points, and make better maintenance decisions",
      },
      {
        logoUrl: objectIntersect,
        altText: "Object intersect icon",
        title:"Comprehensive Integrations ",
        description: "Extend the capabilities of your maintenance management software by connecting to all your most important tools and teams via native integrations and seamless add-ons",
      },
      {
        logoUrl: warehouseFull,
        altText: "Warehouse icon",
        title:"Simplified Inventory Management ",
        description: "Lower inventory costs, increase speed-to-repair, and easily track and visualize your inventory data with specialized equipment maintenance software",
      }
    ],
    // link:{
    //   text: 'Explore the many benefits Accruent software offers',
    //   url: '/request-demo'
    // }
  },
  fullWidthBanner:{
    image: {
      url: fullWidthBannerMc1,
      altText:"Encore Wire"
    },
    title:"CMMS Coordinator, Encore Wire Corporation",
    description: "Before Maintenance Connection, it was very time-consuming to handwrite reports and get information relayed. Now, with the click of a button, technicians can input their labor reports, their hours and more in real time.",
    name: "Kyler Keel",
    position: "CMMS Coordinator",
    companyName: " Encore Wire Corporation"
  },
  logoSlider:{
    logoList:[
      
      {
        url: logo6,
        altText:'Alaska Airlines logo'
      },
      {
        url: logo4,
        altText:"L'Oreal logo"
      },
      {
        url: logo5,
        altText:'The New York Times logo'
      },
      {
        url: logo3,
        altText:'Cook Medical logo'
      },
      {
        url: mastersWhite,
        altText:"Masters Gallery Food logo"
      }
    ]
  },
  features: {
    title: 'Understanding Maintenance Connection For Your Industry',
    description: "",
    logo:{
      url: mcLogoN,
      altText: 'Accruent Maintenance Connection Logo'
    },
    items: [
      {
        logoUrl: charLineLogom,
        altText: "Explore Manufacturing",
        title: "Increased Manufacturing Operational Efficiency",
        description: "Streamline maintenance processes, reduce downtime, and extend asset life by scheduling regular maintenance, tracking repairs and managing parts inventory.",
        link:{
          text:'Explore Manufacturing',
          url:'/industries/manufacturing'
        }
      },
      {
        logoUrl: oilWell,
        altText: "Explore Oil & Gas",
        title: "Single Source of Truth for Oil and Gas ",
        description: "Minimize downtime, improve safety, and respond quickly to critical situations with our robust CMMS and EDMS for oil and gas.",
        link:{
          text:'Explore Oil & Gas',
          url:'/industries/utilities/oil-gas'
        }
      },
      {
        logoUrl: prescriptionBottle,
        altText: "Explore Pharmaceutical",
        title: "Compliance Readiness for Pharmaceuticals and Life Sciences",
        description: "Simplify FDA and ISO regulatory compliance with electronic signatures, simple audit trails, and streamlined procedure versioning.",
        link:{
          text:'Explore Pharmaceutical',
          url:'/industries/manufacturing/process-manufacturing/pharmaceutical'
        }
      }
    ]
  },
  heroBanner1:{
    title: 'Look under the hood',
    description:'Learn more about our software, get your questions answered, and find out how it can complement your current technology and processes',
    link1: {
      url: '/request-demo',
      text: 'Request a demo'
    },
    link2: {
      url: '/contactus',
      text: 'Contact Sales'
    },
    image: {
      url: mcHeroBanner1,
      alt: 'Three customers asking questions and learning more about our software in a collaborative environment'
    }
  },
  LinkStrip: {
    url:'/',
    text:'Calculate what impact Maintenance Connection will have on your business',
    link1:{
      url:'/',
      text:'Calculate Maintenance Connection Pricing'
    },
    link2:{
      url:'/',
      text:'Calculate what impact Maintenance Connection will have on your business'
    }
  },
  resources: {
    title: "Some resources you might find interesting",
    link:{
      url:'/resources',
      text:"Explore Accruent resources"
    },
    resourceList:{
      resource1: {
        image:{
          url:resource1,
          altText: 'Encore representative discussing Maintenance Connection'
        },
        title:"Encore Wire Uses Maintenance Connection to Optimize Asset and Inventory Management",
        description:"Hear from Encore Wire's Kyler Keel about how Maintenance Connection enables more predictive operations and simplifies asset & inventory management.",
        link: 'https://www.accruent.com/resources/case-studies/encore-wire-uses-maintenance-connection-optimize-asset-and-inventory',
        button:{
          text: 'Case Study',
        }
      },
      resource2: {
        image:{
          url:resource2,
          altText: 'Two professionals in an interview setting'
        },
        title:"The New York Times",
        description:"See how the publishing plant easily organized all assets and implemented a preventive maintenance schedule to service equipment before it breaks.",
        link: 'https://website.maintenanceconnection.com/resources/case-studies/new-york-times',
        button:{
          text: 'Case Study',
        }
      },
      list:[
        {
          image:{
            url:resource3,
            altText: 'Two professionals preforming a maintenance check on machinery.'
          },
          title:"How to Save Millions on Maintenance Costs with a CMMS",
          link: 'https://website.maintenanceconnection.com/resources/ebooks/how-save-millions-maintenance-costs-cmms',
          button:{
            text: 'E-book',
          }
        },
        {
          image:{
            url:resource4,
            altText: 'Two food scientists examing their product.'
          },
          title:"Overcome Common Pain Points in Food and Beverage Manufacturing",
          link: '/resources/brochures/f&b-mc-meridian-observe',
          button:{
            text: 'Brochure',
          }
        },
        {
          image:{
            url:resource5,
            altText: 'Engineer examing documents using CMMS and EDMS integration'
          },
          title:"Enhance Your CMMS Capabilities with an EDMS Integration",
          link: 'https://www.accruent.com/resources/brochures/enhance-your-cmms-capabilties-edms-integration',
          button:{
            text: 'Brochure',
          }
        }
      ]
    },
    walkthrough: {
      title:"Walkthrough demonstrations",
      items:[
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"Maintenance Connection: Work Order Management",
          description: "Easily review and assign work orders to your technicians and enable preventive and predictive maintenance programs",
          link: 'https://accruent.wistia.com/medias/cp94edmtf3',
          wistiaId: 'cp94edmtf3'
        },
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"Maintenance Connection: Inventory",
          description: "Avoid stockouts and excess inventory carrying costs with effective inventory management",
          link: 'https://accruent.wistia.com/medias/d4srtqb8lu',
          wistiaId: 'd4srtqb8lu'
        },
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"Maintenance Connection Reports",
          description: "Meet rising expectations and overcome persistent pain points with actionable insights",
          link: 'https://accruent.wistia.com/medias/2ij26o9csc',
          wistiaId: '2ij26o9csc'
        },
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"Maintenance Connection Assets",
          description: "Enable higher asset availability to minimize lost revenue and inefficiencies",
          link: 'https://accruent.wistia.com/medias/a3sl69o438',
          wistiaId: 'a3sl69o438'
        }
      ]
    }
  },
  support:{
    logo:{
      url: mcLogoN,
      altText: 'Accruent Maintenance Connection Logo'
    },
    title: 'Frequently Asked Questions',
    list: [
      {
        title: 'How does Maintenance Connection CMMS help reduce downtime?',
        descList: [
          {
            text: '<span>Through streamlined processes, CMMS provides more efficient work order management so organizations can address issues fast.</span>'
          },
          {
            text: '<span>CMMS assigns <a href="https://website.maintenanceconnection.com/resources/knowledge-hub/what-is-a-work-order" style="text-decoration-line:underline">maintenance tasks</a> and alerts teams quickly, adding tickets to the backend system without delay. This ensures more uptime for equipment and lowers the costs of replacements.</span>'
          },
          {
            text: '<span>Create a centralized hub for task management where managers and assignees can easily track work progress.</span>'
          },
        ]
      },
      {
        title: 'Does Maintenance Connection have real-time tracking and monitoring?',
        descList: [
          {
            text: '<span>Yes, monitor ongoing activities, gauge work orders, and view critical analytics data. Keep your team accountable throughout the entire customer lifecycle.</span>'
          },
          {
            text: '<span>Report on the effectiveness of procedures and develop streamlined maintenance schedules to boost equipment uptime. Visualize the most complex workflows and create simplified reports to manage performance.</span>'
          },
          {
            text: '<span>Real-time updates ensure you and your team are staying on top of key data and measuring changes as needed.</span>'
          }
        ]
      },
      {
        title: 'Is mobile management easy with Maintenance Connection?',
        descList: [
          {
            text: '<span>Maintenance Connection knows that teams might be in various places, whether that is onsite or in the office. The mobile app allows teams to access procedures from any location, updating work orders and identifying inventory across locations.</span>'
          },
          {
            text: '<span>Personnel can easily be assigned to any project. The intuitive interface allows easy meter tracking, checking stock levels, and regulating inventory. They can collaborate and update statuses in a few clicks.</span>'
          },
        ]
      },
      {
        title: 'Is Maintenance Connection offered as a SaaS solution?',
        descList: [
          {
            text: '<span>Maintenance Connection is available as a SaaS solution or an on-premises model. Each of these has unique advantages depending on an organization’s regulatory obligations.</span>'
          },
          {
            text: '<span>The <a href="https://www.accruent.com/uk/resources/blog-posts/true-value-of-saas-oil-and-gas" style="text-decoration-line:underline">SaaS solution</a> allows for easy scalability, allowing users to access the software within a cloud environment. It also reduces the amount of overhead, especially for organizations with limited IT departments.</span>'
          },
          {
            text: '<span>The on-premises model is useful for companies that demand local data control and must meet specific regulatory requirements.</span>'
          },
          {
            text: '<span>Both deployment options give users more control over their CMMS solution, helping them meet their performance and cost goals.</span>'
          }
        ]
      },
      {
        title: 'Why is centralized work order tracking important?',
        descList: [
          {
            text: '<span>Centralized work order tracking helps improve organization-wide visibility into maintenance activities.</span>'
          },
          {
            text: '<span>Maintenance Connection provides simpler communication among all teams, leading to better decision making and resource allocation.</span>'
          },
          {
            text: '<span>A user-friendly interface makes it easy to access work order data on the fly, especially on mobile devices.</span>'
          }
        ]
      }

    ],
    contact: {
      title: 'Don’t see your question? Let us help you',
      link: {
        url:'/contactus',
        text:'Contact support'
      }
    }
  },
  design:{
    title:"Products designed to integrate your world",
    description: "Accruent software allows you to fully optimize the lifecycle of your asset and facilities, offering connected workflows, integrated experiences and data-backed insights.",
    link: {
      url:'/products',
      text:'Explore all Accruent products'
    },
    productList:[
      {
        url: design1,
        altText: 'Accruent EMS Logo',
        link:'/products/ems'
      },
      {
        url:redeyeLogo,
        altText: 'Accruent Redeye Logo',
        link:'/products/redeye'
      },
      {
        url: mcLogo,
        altText: 'Accruent Maintenance Connection Logo',
        link:'/products/maintenance-connection'
      },
      {
        url: service2,
        altText: 'Accruent Lucernex Logo',
        link:'/products/lucernex'
      }
    ]
  }
}

const HeroBanner = (heroImages) => {
  return <>
     <div className={styles.heroContainer}>
        <div className={styles.leftWrapper}>
          <div className={styles.heroLogoTitleWrapper}>
            <img src={data?.heroBanner?.logo?.url} alt={data?.heroBanner?.logo?.altText} className={styles.heroLogo}></img>
            <h1 className={styles.heroTitle}>
              {data?.heroBanner?.title}
            </h1>
          </div>
          <p className={styles.heroDesc}>{data?.heroBanner?.description}</p>
          <div className={styles.heroBtnWrapper}>
            <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.heroBanner?.link1?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src= {Dot}></img>
              </div>
            </a>
            <a href={data?.heroBanner?.link2?.url} className={styles.heroBtn2Wrapper}>
              <span className={styles.heroBtn2Logo}><img src= {Play}></img></span>
              <span className={styles.heroBtn2Text}>{data?.heroBanner?.link2?.text}</span>
            </a>
        </div>
        </div>
        <div>
          {/* <img className={styles.heroImage} src={data?.heroBanner?.image?.url} alt={data?.heroBanner?.image?.altText}></img> */}
          <Picture
            className={styles.heroImage}
            image={heroImages.heroImages['staticPages/mc-hero.jpg']}
            rel="preload"
            alt={data?.heroBanner?.image?.altText}
          />
        </div>
     </div>
  </>
}

const Benefits = () => {
  return (
    <div className={styles.benefitContainer}>
      <div className={styles.benefitGridCompWrapper}>
        <div className={styles.benefitHeadingWrapper}>
          <h2 className={styles.benefitTitle}>{data?.benefits?.title}</h2>
          <p className={styles.benefitDesc}>{data?.benefits?.description}</p>
        </div>
        <div className={styles.featureGridWrapper}>
          {
            data?.benefits?.items?.map((item,index)=>
              <div key={index} className={styles.featureGridCard}>
              <div className={styles.featureBox}>
                <div className={styles.featureWrapper}>
                  <div className={styles.featureLogoWrapper}>
                    <img src={item?.logoUrl} alt={item?.altText} className="tw-min-h-[25px] "></img>
                  </div>
                  <h3 className={styles.featureGridTitle}>
                    {item?.title}
                  </h3>
                  <p className={styles.featureGridDesc}>{item?.description}</p>
                </div>
              </div>
              {item?.link ? (
                  <a href={item?.link?.url} className={styles.featureCardLinkWrapper}>
                    <span className={styles.featureCardLinkText}>{item?.link?.text}</span>
                    <img src={arrowRightBlue} alt='Right arrow'></img>
                  </a>
              ) : ""}
              </div>
            )
          }
        </div>      
      </div>
      <div className={styles.benefitProductsWrapper}>
        <p className={styles.benefitProductsText}>{data?.benefits?.info?.title}</p>
        <div className={styles.benefitProductsLogoList}>
          {
            data?.benefits?.info?.images?.map((image,index)=>
              <div key={index} className={styles.benefitProductsLogo}>
                <img src={image?.url} alt={image?.altText} className="tw-max-h-[90px]"></img>
              </div>
            )
          }
        </div>
        <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.benefits?.info?.link?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src= {Dot}></img>
              </div>
            </a>
      </div>
    </div>
  )
}

const ThreeColComp = () => {
  return (
    <div className={styles.threeColContainer}>
      <div className={styles.threeColHeadWrapper}>
        <h2 className={styles.threeColTitle}>{data?.threeColTwoRowComp?.title}</h2>
        <p className={styles.threeColDesc}>{data?.threeColTwoRowComp?.description}</p>
      </div>
      <div className={styles.threeColGridWrapper}>
        {
          data?.threeColTwoRowComp?.items?.map((item, index)=>
            <div key={index} className={styles.threeColGridBox}>
              <div className={styles.threeColGrid}>
                <div className={styles.threeColLogoWrapper}>
                  <img className="tw-min-h-[25px] tw-min-w-[25px]" src={item?.logoUrl} alt={item?.altText}></img>
                </div>
                <h3 className={styles.threeColGridTitle}>{item?.title}</h3>
                <p className={styles.threeColGridDesc}>{item?.description}</p>
              </div>
            </div>
          )
        }
      </div>
      {data?.threeColTwoRowComp?.link ? (
        <a href={data?.threeColTwoRowComp?.link?.url} className={styles.threColLinkWrapper}>
          <span className={styles.threeColLinkText}>{data?.threeColTwoRowComp?.link?.text}</span>
          <img src={arrowRightBlue} alt='Right arrow'></img>
        </a>
      ) : ""}
    </div>
  )
}

const FullWidthBanner = () => {
  return (
    <div className={styles.fullWidthBannerContainer}>
      <div className={styles.fullWidthBannerWrapper}>
        <div className={styles.fullWidthBannerLogoWrapper}>
          <div className={styles.fullWidthBannerLogo}>
            <img className={styles.fullWidthBannerImage} src={data?.fullWidthBanner?.image?.url} 
              alt={data?.fullWidthBanner?.image?.altText} loading="lazy"></img>
          </div>
        </div>
        <div className={styles.fullWidthBannerTextWrapper}>
          <p className={styles.fullWidthBannerTitle}>
            {data?.fullWidthBanner?.title}
          </p>
          <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.description}</p>
          <div>
            <p className={styles.fullWidthBannerName}>— {data?.fullWidthBanner?.name}</p>
            <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.position},{ data?.fullWidthBanner?.companyName}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const LogoSlider = () => {
  return (
    <div className={styles.logoSliderContainer}>
      <div className={styles.logoStrip}>
      { data?.logoSlider?.logoList?.map((logo, index)=>
        <img key={index} className={styles.logoSliderImage} src={logo?.url} alt={logo?.altText}></img>
      )
      }
      </div>
    </div>
  )
}

const Features = () => {
  return (
    <div className={styles.featureContainer}>
      <div className={styles.featureGridCompWrapper}>
        <div className={styles.featureHeadingWrapper}>
          <img src={data?.features?.logo?.url} alt={data?.features?.logo?.altText} className={styles.heroLogo}></img>
          <h2 className={styles.featureTitle}>{data?.features?.title}</h2>
          <p className={styles.featureDesc}>{data?.features?.description}</p>
        </div>
        <div className={styles.featureGridWrapper}>
          {
            data?.features?.items?.map((item,index)=>
              <div key={index} className={styles.featureGridCard}>
              <div className={styles.featureBox}>
                <div className={styles.featureWrapper}>
                  <div className={styles.featureLogoWrapper}>
                    <img src={item?.logoUrl} alt={item?.altText} className="tw-min-h-[25px] "></img>
                  </div>
                  <h3 className={styles.featureGridTitle}>
                    {item?.title}
                  </h3>
                  <p className={styles.featureGridDesc}>{item?.description}</p>
                </div>
              </div>
              <a href={item?.link?.url} className={styles.featureCardLinkWrapper}>
                <span className={styles.featureCardLinkText}>{item?.link?.text}</span>
                <img src={arrowRightBlue} alt='Right arrow'></img>
              </a>
              </div>
            )
          }
        </div>       
      </div>
    </div>
  )
}

const LogoStrip = () => {
  return (
    <div className={styles.LogoStripContainer}>
      {
        data?.logoStrip?.map((img, index)=>(
          <img key={index} src={img?.url} altText={img?.altText}></img>
        ))
      }
    </div>
  )
}

const HeroBanner1 = () => {
  return <>
     <div className={styles.heroContainer1}>
        <div className={styles.hero1leftWrapper}>
          <h2 className={styles.hero1Title}>
            {data?.heroBanner1?.title}
          </h2>
          <p className={styles.hero1Desc}>{data?.heroBanner1?.description}</p>
          <div className={styles.hero1BtnWrapper}>
            <a href={'#mktoForm'} className={styles.hero1Btn1Wrapper}>
              <span className={styles.hero1Btn1Text}>{data?.heroBanner1?.link1?.text}</span>
              <div className={styles.hero1Btn1}>
                <img className={styles.hero1Btn1Img} src= {Dot}></img>
              </div>
            </a>
            <a href={data?.heroBanner1?.link2?.url} className={styles.hero1Btn2Wrapper}>
              <span className={styles.hero1Btn2Text}>{data?.heroBanner1?.link2?.text}</span>
              <img src= {arrowRight}></img>
            </a>
        </div>
        </div>
        <div>
          <img className={styles.hero1Image} src={data?.heroBanner1?.image?.url} alt={data?.heroBanner1?.image?.alt} loading="lazy"></img>
        </div>
     </div>
  </>
}

const customStylesPricing = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStylesPricing = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const PricingModal = ({ isOpen, onRequestClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStylesPricing} className={''}>
    <div style={{height:'560px', width:'1024px'}}>
      <button onClick={onRequestClose} style={closeButtonStylesPricing}>x</button>
      <div>
        <iframe className="pricing-iframe" src="https://app.calconic.com/api/embed/calculator/6488cb0028c7470029d3e5b5" sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups-to-escape-sandbox allow-popups" title="Calconic_ Calculator" name="Calconic_ Calculator" height="640px" width="1024px" scrolling="yes" ></iframe>
      </div>
    </div>
  </Modal>
);

const LinkStrip = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenROI, setModalIsOpenROI] = useState(false);

  const openModalPricing = () => {
    setModalIsOpen(true);
  };

  const closeModalPricing = () => {
    setModalIsOpen(false);
  };
  const openModalROI = () => {
    setModalIsOpenROI(true);
  };

  const closeModalROI = () => {
    setModalIsOpenROI(false);
  };
  return (
    <>
      <div className={styles.LinkStripContainer} style={{  gridTemplateColumns: '1fr 1fr'}}>
        {/* <a href={data?.LinkStrip?.url} className={styles.LinkStripWrapper} onClick={() => openModal()}> */}
          {/* <span className={styles.LinkStripText}>{data?.LinkStrip?.text}</span> */}
          {/* <img src={arrowRight} alt='Right arrow'></img> */}
          <div className={styles.linkStripLeftWrapper} onClick={() => openModalPricing()} style={{background: 'linear-gradient(0deg,#313030,#313030),#fff'}}>
            <div className={styles.LinkStripWrapper}>
              <span className={styles.LinkStripText + " !tw-text-[17px]"}>{data?.LinkStrip?.link1?.text}</span>
              <img src={arrowRight} alt='Right arrow'></img>
            </div>
            {/* <a href={data?.LinkStrip?.link1?.url} className={styles.LinkStripWrapper}> */}
            {/* </a> */}
          </div>
          <div className={styles.linkStripRightWrapper} onClick={() => openModalROI()}>
            <div className={styles.LinkStripWrapper}>
              <span className={styles.LinkStripText + "  !tw-text-[17px]"}>{data?.LinkStrip?.link2?.text}</span>
              <img src={arrowRight} alt='Right arrow'></img>
            </div>
          </div>
        {/* </a> */}
      </div>
      <PricingModal
        isOpen={modalIsOpen}
        onRequestClose={closeModalPricing}
      />
      <ROICalculatorModal
        isOpen={modalIsOpenROI}
        onRequestClose={closeModalROI}
      />
    </>
  )
}

const Resource = () => {
  return (
    <div className={styles.resourceContainer}>
      <div className={`${styles.resourceHeadingWrapper} container`}>
        <h2 className={styles.resourceTitle}>{data?.resources?.title}</h2>
        <a href={data?.resources?.link?.url} className={styles.resourceLinkWrapper}>
          <span className={styles.resourceLinkText}>{data?.resources?.link?.text}</span>
          <img src={arrowRightBlue} alt='arrow button'></img>
        </a>
      </div>
      <div className={styles.resourceWrapper}>
        <div className={`${styles.resourceCardWrapper} container`}>
          <a href={data?.resources?.resourceList?.resource1?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource1?.image?.url} alt={data?.resources?.resourceList?.resource1?.image?.altText}/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource1?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource1?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource1?.button?.text}</div>
              </div>
            </div>
          </a>
          <a href={data?.resources?.resourceList?.resource2?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource2?.image?.url} alt={data?.resources?.resourceList?.resource2?.image?.altText}/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource2?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource2?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource2?.button?.text}</div>
              </div>
            </div>
          </a>
          <div className={styles.resourceMultiCardWrapper}>
            {
              data?.resources?.resourceList?.list?.map((item, index)=>
              <a key={index} href={item?.link} className={styles.resourceMultiCard}>
              <div>
                <img className={styles.resourceMultiCardImg} src={item?.image?.url} alt={item?.image?.altText}></img>
                <div className={styles.resourceCardBtnWrapper + " md:!tw-hidden !tw-flex tw-mt-[15px] tw-w-fit"}>
                    <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                </div>
              </div>
              <div className={styles.resourceMultiCardTextWrapper}>
                <p className={styles.resourceMultiCardText}>{item?.title}</p>
                <div className={styles.resourceCardBtnWrapper + " md:!tw-flex !tw-hidden"}>
                  <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                </div>
              </div>
            </a>
              )
            }
          </div>
        </div>
      </div>
      <div className={styles.walkthroughWrapper}>
        <h2 className={styles.walkthroughTitle}>{data?.resources?.walkthrough?.title}</h2>
        <div className={styles.walkthroughFlexWrapper}>
          <VideoGrid />
        </div>
      </div>
    </div>
  )
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'hidden'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const VideoModal = ({ isOpen, onRequestClose, wistiaId }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
    <div>
      <button onClick={onRequestClose} style={closeButtonStyles}>x</button>
      <div
        className={`wistia_embed wistia_async_${wistiaId} videoFoam=true`}
        style={{height:'360px', width:'640px'}}
      >
        &nbsp;
      </div>
    </div>
  </Modal>
);

const VideoGrid = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openModal = (wistiaId) => {
    setCurrentVideo(wistiaId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo(null);
  };

  return (
    <>
      {data?.resources?.walkthrough?.items?.map((video, index) =>
        <div className={styles.walkthroughCardWrapper} onClick={() => openModal(video.wistiaId)}>
          <img src={video?.logo?.url} alt={video?.logo?.altText}></img>
          <div className={styles.walkthroughCardText}>
            <p className={styles.walkthroughCardTitle}>{video?.title}</p>
            <p className={styles.walkthroughCardDesc}>{video?.description}</p>
          </div>
        </div>
      )
      }
      <VideoModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        wistiaId={currentVideo}
      />
    </>
  );
};

const Question = ({item}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={styles.supportListWrapper}>
      <div onClick={()=>setOpen(!open)} className={styles.supportListTitleWrapper}>
        <img src={open ? arrowUpBlue : arrowDownBlue} alt='arrow'></img>
        <h3 className={styles.supportListTitle}>{item?.title}</h3>
      </div>
      {open && <div className={styles.supportListDescWrapper}>
          {
            item?.descList?.map((i, index)=>
              <p key={index} className={styles.supportListDesc} dangerouslySetInnerHTML={{ __html: i?.text }}></p>
            )
          }
        </div>
      }
    </div>
  )
}

const Support = () => {
  return (
    <div className={styles.supportContainer}>
      <div className={styles.supportHeadWrapper}>
        <img className={styles.supportLogo} src={data?.support?.logo?.url} alt={data?.support?.logo?.altText}></img>
        <h2 className={styles.supportTitle}>{data?.support?.title}</h2>
        <div className={styles.supportQListWrapper}>
        {
          data?.support?.list?.map((item, index)=>
            <Question key={index} item={item}/>
          )
        }
      </div>
      </div>
      <div className={styles.supportContactWrapper}>
        <p className={styles.supportContactText}>{data?.support?.contact?.title}</p>
        <a className={styles.supportContactLink} href={data?.support?.contact?.link?.url}>
          <span className={styles.supportContactLinkText}>{data?.support?.contact?.link?.text}</span>
          <img src={arrowRightBlue} alt='arrow right'></img>
        </a>
      </div>
    </div>
  )
}

const Design = () => {
  return (
    <div className={styles.designContainer}>
      <div className={styles.designTextWrapper}>
        <div className={styles.designTitleWrapper}>
          <h2 className={styles.designTitle}>{data?.design?.title}</h2>
          <div className={styles.designDescWraper}>
            <p className={styles.designDesc}>{data?.design?.description}</p>
            <a href={data?.design?.link?.url} className={styles.designLink}>
              <span className={styles.designLinkText}>{data?.design?.link?.text}</span>
              <img src={arrowRightBlue} alt='arrow img'></img>
            </a>
          </div>
        </div>

      </div>
      <div className={styles.designLogoContainer}>
        <div className={styles.designLogoStrip}>
          {
            data?.design?.productList?.map((item, index)=>
              <a href={item?.link}  key={index} className={styles.designLogoWrapper}>
                <img className={styles.designLogo} src={item?.url} alt={item?.altText}></img>
              </a>
            )
          }
        </div>
      </div>
    </div>
  )
}

const customStylesROI = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStylesROI = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const ROICalculatorModal = ({ isOpen, onRequestClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStylesROI} className={''}>
    <div style={{height:'560px', width:'1024px'}}> 
      <button onClick={onRequestClose} style={closeButtonStylesROI}>x</button>
      {/* <div> */}
        <ROICalculator
              data={data.ROICalculator}
        />
      {/* </div> */}
    </div>
  </Modal>
);

export default function Mc() {
  const heroImages = useHeroImages();
  const formImages = useFormImages()
  const pageDataPath = getPageDataJsonPath('/products/maintenance-connection')
  const contentEntity = {
    "uuid": "abfd837b-6954-4cb8-a9c1-9c100cccda3b",
    "nid": 4046,
    "fieldEmbedCode": "<script src=\"//app-abc.marketo.com/js/forms2/js/forms2.min.js\"></script>\r\n<form id=\"mktoForm_1693\"></form>\r\n<script>MktoForms2.loadForm(\"//app-abc.marketo.com\", \"167-BOY-362\", 1693, function(form) {\r\n    dataLayer.push({\r\n      'event': 'gaTriggerEvent',\r\n      'gaCategory': 'CTA Engagement',\r\n      'gaAction': 'Form Load',\r\n      'gaLabel': 'Demo Request',\r\n    });\r\n    form.onSuccess(function(values, followUpUrl) {\r\n      dataLayer.push({\r\n        'event': 'gaTriggerEvent',\r\n        'gaCategory': 'CTA Engagement',\r\n        'gaAction': 'Form Submission',\r\n        'gaLabel': 'Demo Request',\r\n      });\r\n    });\r\n  });\r\n</script>",
    "title": "Request A Demo ",
    "fieldFormHeader": "Request A Demo",
    "fieldHideTitle": false,
    "fieldShowTitleFullWidth": null,
    "fieldFormText": null,
    "fieldFormImage": {
        "alt": "Form Image"
    },
    "relationships": {
        "fieldFormImage": {
        },
        "fieldFormImageWithCover": formImages['staticPages/form-face.png'],
        "fieldMediaImage": {
            "fieldImage": {
                "alt": "bg"
            },
            "relationships": {
                "field_image": formImages['staticPages/form-bg.jpg'],
            }
        }
    },
    "type": {
        "id": "marketo_forms"
    },
    "fieldVersions": "advanced",
    "fieldCtaUrl": null
  }
  return (
    <>
      <SEO
        meta={
          [
            { 'name': 'title', 'content': "Maintenance Connection's CMMS Software | Accruent" },
            { 'name': 'description', 'content': "Simplify asset management with Accruent's Maintenance Connection, a complete CMMS software for work orders, preventive maintenance, and inventory control." }
          ]
        }
        heroImage={heroImages['staticPages/mc-hero.jpg']}
        preloads= {[pageDataPath]}
        lang="en"
        pageUrl="/products/maintenance-connection"
        alternatives={[
          {
              "href": "/products/maintenance-connection",
              "hreflang": "en"
          },
          {
              "href": "/uk/products/maintenance-connection",
              "hreflang": "en-gb"
          },
          {
              "href": "/products/maintenance-connection",
              "hreflang": "x-default"
          }
      ]}
      />
      <HeroBanner heroImages={heroImages}/>
      <LogoStrip />
      <Benefits/>
      <ThreeColComp/>
      <FullWidthBanner/>
      <LogoSlider/>
      <Features/>
      <HeroBanner1/>
      <LinkStrip/>
      <Resource/>
      <Support/>
      <div id="mktoForm">
        <ContentMarketoForm
          entity={contentEntity}
          locale="en"
          pageTitle="Maintenance Connection: Computerized Maintenance Management System Software (CMMS)"
          formFullWidth={false}
        />
      </div>
      <Design/>
    </>
  );
}